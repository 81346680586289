const YoutubeIcon = () => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.8351" cy="19.25" r="15" fill="white" />
    <path
      d="M28.0791 15.0816C27.9818 14.7154 27.7901 14.3813 27.5231 14.1126C27.2561 13.8438 26.9232 13.65 26.5577 13.5504C25.2158 13.1884 19.8351 13.1884 19.8351 13.1884C19.8351 13.1884 14.4543 13.1884 13.1125 13.5504C12.747 13.65 12.414 13.8438 12.147 14.1126C11.88 14.3813 11.6883 14.7154 11.5911 15.0816C11.2314 16.432 11.2314 19.25 11.2314 19.25C11.2314 19.25 11.2314 22.068 11.5911 23.4187C11.6883 23.7849 11.88 24.119 12.147 24.3877C12.414 24.6565 12.747 24.8503 13.1125 24.9499C14.4543 25.3116 19.8351 25.3116 19.8351 25.3116C19.8351 25.3116 25.2158 25.3116 26.5577 24.9499C26.9232 24.8503 27.2561 24.6565 27.5231 24.3877C27.7901 24.119 27.9818 23.7849 28.0791 23.4187C28.4387 22.068 28.4387 19.25 28.4387 19.25C28.4387 19.25 28.4387 16.432 28.0791 15.0816Z"
      fill="#EA5A47"
    />
    <path d="M18.2478 21.5575L22.3043 19.25L18.2478 16.9425V21.5575Z" fill="white" />
  </svg>
)
export default YoutubeIcon
