const XIcon = (props) => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.7203" cy="19.25" r="15" fill="white" />
    <mask
      id="mask0_25_29"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="10"
      width="18"
      height="18"
    >
      <path d="M11.2528 10.7825H28.1879V27.7175H11.2528V10.7825Z" fill="white" />
    </mask>
    <g mask="url(#mask0_25_29)">
      <path
        d="M24.5892 11.576H27.1863L21.513 18.0766L28.1879 26.924H22.9622L18.8663 21.5592L14.185 26.924H11.5855L17.653 19.9685L11.2528 11.5772H16.6115L20.3082 16.4799L24.5892 11.576ZM23.6759 25.366H25.1154L15.8253 13.053H14.2818L23.6759 25.366Z"
        fill="#3A3A3A"
      />
    </g>
  </svg>
)

export default XIcon
