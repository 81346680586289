const GithubIcon = () => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.8925" cy="19.25" r="15" fill="white" />
    <path
      d="M16.1093 11.5729C16.7441 11.788 17.3479 12.0852 17.9054 12.457C18.6928 12.2558 19.5024 12.1547 20.3151 12.1561C21.1544 12.1561 21.9641 12.2609 22.7231 12.4562C23.2805 12.0847 23.884 11.7878 24.5184 11.5729C25.1075 11.3726 25.9468 11.0481 26.4455 11.6C26.7836 11.9753 26.8681 12.6041 26.9281 13.0842C26.9957 13.6201 27.0118 14.3182 26.8343 15.0113C27.513 15.8878 27.9221 16.9333 27.9221 18.0726C27.9221 19.7986 26.9873 21.2971 25.6036 22.3351C24.9378 22.8283 24.1992 23.2149 23.4145 23.4812C23.5954 23.8953 23.696 24.3534 23.696 24.8344V27.37C23.696 27.5942 23.6069 27.8091 23.4484 27.9677C23.2899 28.1262 23.0749 28.2152 22.8508 28.2152H17.7795C17.5553 28.2152 17.3403 28.1262 17.1818 27.9677C17.0233 27.8091 16.9343 27.5942 16.9343 27.37V26.5324C16.1271 26.6313 15.4501 26.5434 14.8745 26.2991C14.2727 26.0439 13.8535 25.6483 13.5382 25.2696C13.239 24.9113 12.9127 24.1032 12.4411 23.946C12.3358 23.911 12.2384 23.8555 12.1545 23.7828C12.0706 23.7101 12.0018 23.6215 11.9522 23.5222C11.8518 23.3217 11.8353 23.0896 11.9061 22.8768C11.9769 22.6641 12.1294 22.4882 12.3299 22.3879C12.5304 22.2876 12.7626 22.271 12.9753 22.3418C13.5382 22.5295 13.905 22.9352 14.1561 23.2614C14.5618 23.7854 14.8914 24.4701 15.5338 24.7431C15.7983 24.8555 16.1863 24.929 16.7931 24.8462L16.9343 24.8175C16.9359 24.3575 17.0317 23.9027 17.2157 23.4812C16.431 23.2149 15.6925 22.8283 15.0266 22.3351C13.643 21.2971 12.7082 19.7994 12.7082 18.0726C12.7082 16.935 13.1164 15.8903 13.7935 15.0147C13.616 14.3216 13.6312 13.6217 13.6988 13.085L13.703 13.0529C13.7647 12.561 13.8366 11.982 14.1814 11.6C14.6801 11.0481 15.5202 11.3735 16.1085 11.5738L16.1093 11.5729Z"
      fill="#3A3A3A"
    />
  </svg>
)

export default GithubIcon
