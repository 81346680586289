/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components'
import Image from 'next/image'
import { Button, Flex, Logo, TwitterIcon, useMatchBreakpoints } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { Link } from 'react-scroll'
import { useEffect, useState } from 'react'
import { Drawer, Dropdown, MenuProps, Popover as AntPopover } from 'antd'
import UserWalletWithModal from './UserWalletWithModal'
import MenuIcon from './MenuIcon'
import XIcon from './XIcon'
import TelegramIcon from './TelegramIcon'
import FacebookIcon from './FacebookIcon'
import YoutubeIcon from './YoutubeIcon'
import GithubIcon from './GithubIcon'
import ShareIcon from './ShareIcon'

const MENU_HEIGHT = 84

const WrapMenu = styled.div`
  overflow: hidden;
  .nav {
    position: fixed;
    z-index: 20;
    background-color: #0bffda4d;
    transition: top 0.2s ease 0s;
    .logo {
      max-height: 50px;
    }
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 84px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 84px;
    }
    @media (max-width: 1025px) {
      padding: 17px 20px;
      .left-menu {
        gap: 10px;
      }
    }
    .ant-dropdown .ant-dropdown-menu,
    .ant-dropdown-menu-submenu .ant-dropdown-menu {
      background-color: #000000 !important;
    }
  }
  .historyMn {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    z-index: 10;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.6);
    gap: 10px;
  }
`

const DropdownCustom = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #000000 !important;
  }
`

const Container = styled.div``

const InnerBody = styled.div`
  width: 100%;
  height: calc(100vh + ${MENU_HEIGHT * 1.2}px);
  background-image: url('/images/background/zuki-bg.png');
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: #fff;
  @media only screen and (max-width: 768px) {
    height: calc(100vh);
    margin-top: 0px;
  }
  .left-menu {
    a {
      min-width: 155px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #003b52;
      text-transform: uppercase;
      cursor: pointer;
      text-shadow: rgba(255, 121, 23, 0.49) 0px 0px 6px;
    }
  }
  .header__link {
    color: rgb(255, 255, 255);
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    height: 50px;
    min-width: 90px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    padding: 0px 10px;
    margin-left: 20px;
    position: relative;
    border-radius: 14px;
    transition: all 0.3s ease 0s;
    animation: 1.5s linear 0s infinite normal none running BoxOver;
    cursor: pointer;
    z-index: 1;
  }
  .header__link {
    span {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid rgb(37, 153, 253);
      border-radius: 50px;
      animation: 1s linear 0s infinite normal none running BoxOverOut;
    }
  }
  .header__link::before {
    content: '';
    height: 46px;
    width: 100%;
    display: block;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: linear-gradient(rgb(56, 214, 239) 0%, rgb(0, 196, 255) 100%);
    box-shadow: rgba(105, 145, 255, 0.62) 0px 3px 6px;
    z-index: -2;
    border-radius: 50px;
  }
  .header__link::after {
    content: '';
    height: 46px;
    width: 100%;
    display: block;
    top: 0px;
    left: 0px;
    position: absolute;
    background: linear-gradient(rgb(44, 254, 216) 0%, rgb(175, 255, 248) 100%);
    box-shadow: rgba(105, 145, 255, 0.62) 0px 3px 6px;
    z-index: -1;
    border-radius: 50px;
  }

  @media screen and (min-width: 576px) {
    .header__link {
      font-size: 18px;
      min-width: 140px;
      padding: 0px 30px;
    }
  }
  @-webkit-keyframes BoxOver {
    0% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    to {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

  @keyframes BoxOver {
    0% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    to {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

  @-webkit-keyframes BoxOverOut {
    0% {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      opacity: 1;
      border-width: 2px;
    }

    80% {
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      opacity: 0;
      border-width: 2px;
    }

    to {
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      opacity: 0;
      border-width: 2px;
    }
  }

  @keyframes BoxOverOut {
    0% {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      opacity: 1;
      border-width: 2px;
    }

    80% {
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      opacity: 0;
      border-width: 2px;
    }

    to {
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      opacity: 0;
      border-width: 2px;
    }
  }

  .header__link.play_now::before {
    background: linear-gradient(rgb(253, 156, 0) 0px, rgb(253, 153, 37) 100%);
    box-shadow: rgba(253, 153, 37, 0.62) 0px 3px 6px;
  }
  .header__link.play_now::after {
    background: linear-gradient(rgb(253, 156, 0) 0px, rgb(253, 195, 37) 100%);
    box-shadow: rgba(253, 153, 37, 0.62) 0px 3px 6px;
  }
`
const Footer = styled.div`
  text-align: center;
  color: #686868;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #02365c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  gap: 15px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 100px;
    .divider {
      display: none;
    }
  }
`

export const StyledButton = styled(Button)`
  width: 148.16px;
  border-radius: 7px;
  color: #fff;
  padding: 2px;
  margin-left: 6px;
  @media only screen and (max-width: 768px) {
    width: 69.53px;
    height: 22.05px;
    opacity: 0px;
  }
`
const StyledConnect = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }
`

export const Contract = styled.div<any>`
  min-height: 60px;
  padding: 6px 10px;
  background: rgb(0, 255, 216);
  margin-top: ${(props) => (props.marginTop !== 0 ? -props.marginTop - 20 : -props.marginTop)}px;
  transition: margin-top 0.4s ease 0s;
  display: flex;
  flex-flow: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  p {
    color: rgb(255, 255, 255);
    font-family: Kanit;
    font-weight: bold;
    font-size: 13px;
    line-height: 130%;
    text-transform: uppercase;
    margin-right: 20px;
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 24px;
    }
  }

  a {
    color: rgb(24, 98, 45);
    font-family: Kanit;
    font-size: 13px;
    line-height: 130%;
    text-transform: uppercase;
  }

  @media screen and (min-width: 576px) {
    a {
      font-size: 24px;
    }
  }
`

const Popover = styled(AntPopover)`
  .ant-popover-arrow:before {
    background-color: #007c6966 !important;
  }
`

export const StyledLink = styled.li`
  a {
    color: rgb(240, 255, 244);
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 29px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 7px 20px;
    border-bottom: 1px solid rgb(180, 223, 242);
    svg {
      display: none;
    }
  }
  a:hover {
    background: linear-gradient(rgb(56, 214, 239) 0%, rgb(0, 196, 255) 100%);
    svg {
      display: block;
    }
  }
`
const StyledList = styled.ul`
  list-style: none;
  max-height: 400px;
  background: #007c6966;
  box-shadow: rgba(25, 19, 38, 0.1) 0px 2px 12px -8px, rgba(25, 19, 38, 0.05) 0px 1px 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  min-width: 230px;
`

const StyledMenuDrawer = styled.div`
  padding: 20px 20px 20px 60px;
  margin: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(13px);
  border-radius: 10px;
  overflow: hidden auto;
`
const Menu = ({ children }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false)
    }
  }

  const content = (
    <StyledList>
      <StyledLink>
        <a
          href="https://pancakeswap.finance/swap?outputCurrency=0x2F123CA42F3825ceCB0293fa044292D24f8Cbb51&inputCurrency=0x55d398326f99059fF775485246999027B3197955"
          target="_blank"
          rel="noreferrer"
        >
          EXCHANGE
          <ShareIcon />
        </a>
      </StyledLink>
      {/* <StyledLink>
        <a href="https://airdrop-nft.zukimoba.com/airdrop-cmc" target="_blank" rel="noreferrer">
          AIRDROP <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://farm.zukimoba.com/lps-zuki-bnb-new" target="_blank" rel="noreferrer">
          FARMING
          <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://shop.zukimoba.com/zp" target="_blank" rel="noreferrer">
          ZP SHOP
          <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://auction.zukimoba.com/all-auction?v=all" target="_blank" rel="noreferrer">
          AUCTION
          <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://sumon.zukimoba.com/generate" target="_blank" rel="noreferrer">
          ASSEMBLING
          <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://market.zukimoba.com/market?sort_type=ASC" target="_blank" rel="noreferrer">
          MARKETPLACE
          <ShareIcon />
        </a>
      </StyledLink>
      <StyledLink>
        <a href="https://market.zukimoba.com/my-nfts" target="_blank" rel="noreferrer">
          MY NFTS
          <ShareIcon />
        </a>
      </StyledLink> */}
    </StyledList>
  )

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          href="https://beurl.app/LkSFbhix"
          target="_blank"
          style={{
            color: '#FFF',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <XIcon />
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          href="https://beurl.app/tvgSitHv"
          target="_blank"
          style={{
            color: '#003B52',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          href="https://beurl.app/bpuLTJeD"
          target="_blank"
          style={{
            color: '#003B52',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a
          href="https://beurl.app/OEXpORZx"
          target="_blank"
          style={{
            color: '#003B52',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          <YoutubeIcon />
        </a>
      ),
    },
  ]

  return (
    <WrapMenu>
      <InnerBody>
        <Contract marginTop={scrollY}>
          <p>
            ZUKI CONTRACT ADDRESS: <a>0x2f123ca42f3825cecb0293fa044292d24f8cbb51</a>
          </p>
        </Contract>
        <div style={{ display: 'flex', justifyContent: 'center' }} className="nav">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '1366px', gap: 30 }}>
            <div style={{ minHeight: 50, cursor: 'pointer' }}>
              <Image src="/images/logo/logo.png" alt="avb" width={70} height={70} />
            </div>

            {!isDesktop ? (
              <></>
            ) : (
              <>
                <Flex className="left-menu" alignItems="center" style={{ gap: 15 }}>
                  <a href="/" style={{ color: '#003B52', cursor: 'pointer' }}>
                    HOME
                  </a>
                  <a
                    href="https://zukiverse.gitbook.io/"
                    target="_blank"
                    style={{ color: '#003B52', cursor: 'pointer' }}
                    rel="noreferrer"
                  >
                    DOCUMENTS
                  </a>
                  <a
                    href="mailto:contact@zukiverse.org"
                    target="_blank"
                    style={{ color: '#003B52', cursor: 'pointer' }}
                    rel="noreferrer"
                  >
                    CONTACT US
                  </a>
                </Flex>
              </>
            )}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
              <div style={{ position: 'relative' }}>
                <Popover
                  placement="bottom"
                  content={content}
                  overlayInnerStyle={{ padding: 0, borderRadius: 10, background: 'transparent' }}
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <div className="header__link mobile">
                    <span />
                    Dapp
                  </div>
                </Popover>
                <div />
              </div>
              {/* <a
                href="https://url.zukimoba.com/play-now"
                target="_blank"
                rel="noreferrer"
                className="header__link play_now mobile"
              >
                <span />
                PLAY NOW
              </a> */}
            </div>
            {!isDesktop ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div onClick={toggleMenu} className="my-dropdown">
                  <MenuIcon />
                </div>
                <Drawer
                  placement="top"
                  title={null}
                  contentWrapperStyle={{ background: '#0bffda4d' }}
                  bodyStyle={{ background: '#0bffda4d', border: 'none' }}
                  headerStyle={{ background: '#0bffda4d', borderBottom: '1px solid #0bffda4d' }}
                  width="100%"
                  height="100%"
                  open={isMenuOpen}
                  closable={false}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Image src="/images/logo/logo.png" alt="avb" width={70} height={70} />
                    <div style={{ cursor: 'pointer' }} onClick={toggleMenu}>
                      <CloseCircleOutlined style={{ fontSize: 32 }} />
                    </div>
                  </div>
                  <StyledMenuDrawer>
                    <a href="/" style={{ color: '#003B52', cursor: 'pointer' }}>
                      HOME
                    </a>
                    <a
                      href="https://zukiverse.gitbook.io/"
                      target="_blank"
                      style={{ color: '#003B52', cursor: 'pointer' }}
                      rel="noreferrer"
                    >
                      DOCUMENTS
                    </a>
                    <a
                      href="mailto:contact@zukiverse.org"
                      target="_blank"
                      style={{ color: '#003B52', cursor: 'pointer' }}
                      rel="noreferrer"
                    >
                      CONTACT US
                    </a>
                  </StyledMenuDrawer>
                </Drawer>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {children}
      </InnerBody>
      <Footer>
        <div style={{ fontSize: 24 }}>contact@zukiverse.org</div>
        <div className="divider" style={{ fontSize: 30 }}>
          |
        </div>
        <div style={{ display: 'flex', gap: 6 }}>
          <a href="https://t.me/ZukiverseChannel" target="_blank" rel="noreferrer">
            <TelegramIcon />
          </a>
          <a href="https://x.com/Zukiverses" target="_blank" rel="noreferrer">
            <XIcon />
          </a>
          <a href="https://www.facebook.com/Zukiverses" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.youtube.com/@Zukiverses" target="_blank" rel="noreferrer">
            <YoutubeIcon />
          </a>
          <a href="https://github.com/Zukiverse" target="_blank" rel="noreferrer">
            <GithubIcon />
          </a>
        </div>
      </Footer>
    </WrapMenu>
  )
}

export default Menu
